import React from 'react';
import { Modal } from 'react-bootstrap';
import IframeComm from 'react-iframe-comm';
import { InfoCircle } from 'react-bootstrap-icons';

const ReportModal = ({ modalName, dashAttributes }) => {

    return (
        <div id="conformanceModal">
            <Modal.Header closeButton>
                <Modal.Title style={{ width: '100%' }}>
                    {(modalName === 'ADT Mapping Analysis') ? (
                        <span>{modalName}<p style={{ fontSize: "15px", marginBottom: "0" }}><InfoCircle /> This report is up to date from the last mapping table load.</p></span>
                    ) : (
                        <span>{modalName}</span>
                    )}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <IframeComm attributes={dashAttributes} />
            </Modal.Body>
        </div>
    );

}

export default ReportModal;