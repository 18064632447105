import React, { forwardRef, useEffect, useState } from 'react';
import MaterialTable from '@material-table/core';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { Accordion, Alert, Button, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import FileSaver from 'file-saver';
import JSZip from 'jszip';

import { ArrowClockwise } from 'react-bootstrap-icons';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import fieldInterest from "../../jsonConstants/MedRecInterestField.json"
import './MedRecFalloutDashboard.css';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

function downloadIcon() {
    return <SaveAlt style={{ color: "#8A8B8D", fontSize: "30px" }} />
};



const MedRecFalloutDashboard = () => {

    const idToken = useSelector((state) => state.tokens.idToken);

    //for download checkbox color
    const theme =
        createTheme({
            palette: {
                secondary: {
                    main: '#23B2D2'
                }
            },

        });

    const [controlOptions, setControlOptions] = useState(
        {
            'facility': '',
            'dateFilter': '',
            'startDate': new Date().toISOString().split("T")[0],
            'endDate': new Date().toISOString().split("T")[0],
            'fieldInterest': '',
            'conformanceStatus': '',
            'numMessages': 30
        }
    );

    const [tableData, setTableData] = useState([]);
    const [facilityNames, setFacilityNames] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        //hook up call to pull in facility/group names for selection drop down
        retrieveFacilities();
    }, [])

    /**
     * Handles changes to the Control values
     * @param e 
     */
    const handleChange = (e) => {

        const { name, value } = e.target;

        //change date filtering and custom range date selections based on the option chosen
        if (name === 'dateFilter') {
            let dates = findDates(value);
            setControlOptions({ ...controlOptions, dateFilter: value, endDate: dates.end, startDate: dates.start });
        }
        else if (name === 'startDate' || name === 'endDate') {
            setControlOptions({ ...controlOptions, [name]: value, dateFilter: "" });
        }
        else {
            setControlOptions({ ...controlOptions, [name]: value });
        }
    }

    /**
     * Provides the values for Start and End Date when a date filter is selected
     * @param filterType - the range of time to find
     * @returns a dictionary containing the appropriate start and end dates relating to the date filter
     */
    const findDates = (filterType) => {
        let start;
        let end = new Date().toISOString().split("T")[0];
        if (filterType === "Yesterday") {
            let yesterday = new Date((new Date()).valueOf() - 1000 * 60 * 60 * 24).toISOString().split("T")[0];
            start = yesterday;
            end = yesterday;
        }
        else if (filterType === "Last Week") {
            start = new Date((new Date()).valueOf() - 1000 * 60 * 60 * 168).toISOString().split("T")[0];
        }
        else if (filterType === "Last Month") {
            start = new Date((new Date()).valueOf() - 1000 * 60 * 60 * 720).toISOString().split("T")[0];
        }
        else if (filterType === "Last Quarter") {
            start = new Date((new Date()).valueOf() - 1000 * 60 * 60 * 2160).toISOString().split("T")[0];
        }
        else if (filterType === "Last Year") {
            start = new Date((new Date()).valueOf() - 1000 * 60 * 60 * 8760).toISOString().split("T")[0];
        }
        else {
            start = "";
            end = "";
        }
        return { "start": start, "end": end };
    }

    const generateZip = (parsedResult, fileIds) => {

        //set up zip
        let today = new Date().toISOString().split("T")[0];
        let zipName = 'Non-Conformant CCDA Messages-' + today + '.zip'
        const zip = new JSZip();

        //loop through returned messages and create files
        for (let i = 0; i < parsedResult.length; i++) {
            let fileName = fileIds[i] + '.xml';
            zip.file(fileName, parsedResult[i]);
        }

        //download zip
        try {
            zip.generateAsync({ type: 'blob' })
                .then(function (content) {
                    FileSaver.saveAs(content, zipName);
                });
        }
        catch (err) {
            console.log("error downloading " + err);
        }
    }


    /**
     * On download click: calls getFileFromS3 to return all selected CCDAs, zips the returned files, and downloads
     * @param event 
     * @param data 
     */
    const downloadFiles = async (event, data) => {

        //activate screen overlay until download is complete
        setIsDownloading(true);

        let myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Authorization', idToken);

        //grab all file IDs for selected rows
        let fileIds = [];
        let fileNames = [];
        data.forEach(element => {
            fileIds.push(element.file_id);
            fileNames.push(element.file_id);  // Keep this list of file IDs intact for download file names
        });

        let messageList = [];
        let callURL = `${process.env.REACT_APP_CONFORMANCE_GET_FILE_URL}/?type=ccda`;

        // split file IDs into groups of 20
        while (fileIds.length > 0) {
            let group = fileIds.splice(0, 20);
            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: group
            }

            await fetch(callURL, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                    let parsedResult = JSON.parse(result);
                    messageList = messageList.concat(parsedResult.message);
                })
        }

        generateZip(messageList, fileNames);
        setIsDownloading(false);
    }

    /**
     * Retrieves the groups / facility that are associated with a user's conformance groups on their ID token
     */
    const retrieveFacilities = async () => {

        let myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Authorization', idToken);

        let queryFacilitiesEndpoint = `${process.env.REACT_APP_CONFORMANCE_APIS}/retrieve-facilities?name=medrec`;

        let requestOptions = {
            method: 'GET',
            headers: myHeaders
        };

        let parsedResult;

        fetch(queryFacilitiesEndpoint, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                parsedResult = JSON.parse(result);

                let facilityNames = [];
                parsedResult.forEach((group, _index) => {
                    group.FacilityNames.forEach((facility, _index) => {
                        //replace extraneous quotations
                        let cleanFacilityName = facility.replaceAll('"', '')
                        //check if facility has already been added to the list in a prior group
                        if (!facilityNames.includes(cleanFacilityName)) {
                            facilityNames.push(cleanFacilityName);
                        }
                    })
                })

                setFacilityNames(facilityNames);
            })
            .catch((err) => {
                console.log("Error");
                console.log(err);
            })
    }


    /**
     * Creates option object for messages /query request
     * @param {*} facility - Sending facility associated with messages
     * @param {*} start - Start date
     * @param {*} end - End date
     * @param {*} fields - Fields of interest to search for conformance status
     * @param {*} status - Non-conformance / conformance status
     * @param {*} limit - Number messages to query
     * @param {*} headers - Request headers
     * @returns 
     */
    const createRequestOptions = (facility, start, end, fields, status, limit, headers) => {
        let queryBody = {
            "sendingFacility": facility,
            "startDate": start,
            "endDate": end,
            "conformanceFields": fields,
            "conformanceStatus": status,
            "limit": limit
        };

        let requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(queryBody)
        };

        return requestOptions
    }


    /**
     * Retrieves MedRec Messages that match the selected filters
     */
    const retrieveMedRecs = async () => {
        setIsLoading(true);
        setTableData([]);

        let myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Authorization', idToken);

        let queryMedRecEndpoint = `${process.env.REACT_APP_CONFORMANCE_APIS}/medrec/query`;

        // if single date selected, execute single query
        if (controlOptions.startDate === controlOptions.endDate) {
            let options = createRequestOptions(controlOptions.facility, controlOptions.startDate,
                controlOptions.endDate, [controlOptions.fieldInterest], parseInt(controlOptions.conformanceStatus),
                controlOptions.numMessages, myHeaders);

            fetch(queryMedRecEndpoint, options)
                .then((response) => response.text())
                .then((result) => {
                    let parsedResult = JSON.parse(result);
                    if (parsedResult !== "Error performing conformance query") {
                        setTableData(parsedResult);
                    }
                })
                .catch((err) => {
                    console.log("Error: " + err);
                })

            setIsLoading(false);
        }
        // if date range selected; query day by day
        else {
            let currDate = controlOptions.startDate;
            // 'end' represents the date the loop should end on (one day past the last day to be queried)
            const end = new Date((new Date(controlOptions.endDate)).valueOf() + 1000 * 60 * 60 * 24).toISOString().split("T")[0];

            let results = [];
            let parsedResults = {};
            let limit = parseInt(controlOptions.numMessages);

            // query for messages while limit hasn't been reached and date range has not been completed
            while (currDate !== end && limit > 0) {
                let options = createRequestOptions(controlOptions.facility, currDate,
                    currDate, [controlOptions.fieldInterest], parseInt(controlOptions.conformanceStatus),
                    limit, myHeaders);

                await fetch(queryMedRecEndpoint, options)
                    .then((response) => response.text())
                    .then((result) => {
                        parsedResults = JSON.parse(result);
                        if (parsedResults !== "Error performing conformance query") {
                            results = results.concat(parsedResults);
                        }
                    })
                    .catch((err) => {
                        console.log("Error: " + err);
                    })

                // account for retrieved messages in limit
                limit = parseInt(limit - parsedResults.length);
                // update currDate to following day
                currDate = new Date((new Date(currDate)).valueOf() + 1000 * 60 * 60 * 24).toISOString().split("T")[0];

            }
            setTableData(results);
            setIsLoading(false);
        }
    }

    const refreshTable = () => {
        //check that all needed controls are populated
        if (controlOptions.facility !== "" && controlOptions.startDate !== "" && controlOptions.endDate !== "" && controlOptions.conformanceStatus !== "" && controlOptions.fieldInterest !== "") {
            retrieveMedRecs();
        }
    }

    useEffect(() => {
        if (tableData.length > 0 && tableData.length < parseInt(controlOptions.numMessages, 10)) {
            setShowAlert(true);
        }
        else {
            setShowAlert(false);
        }
    }, [tableData, controlOptions])

    useEffect(() => {
        //refresh table when controls are updated
        refreshTable();
    }, [controlOptions])

    return (
        <>
            <LoadingOverlay
                active={isDownloading}
                spinner
                text='Downloading selected MedRecs...'>
                {showAlert && (
                    <Alert id='num-message-alert'>You chose {controlOptions.numMessages} messages but only {tableData.length} exist. All available messages will be shown in the table below.</Alert>
                )}
                <Accordion defaultActiveKey="0" flush>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Controls</Accordion.Header>
                        <Accordion.Body>
                            <Row>
                                <Col sm={{ span: 2 }}>
                                    <Form.Label>Number of Messages:</Form.Label>
                                </Col>
                                <Col sm={{ span: 1 }}>
                                    <Form.Select name="numMessages" value={controlOptions.numMessages} onChange={handleChange}>
                                        <option>30</option>
                                        <option>60</option>
                                        <option>90</option>
                                        <option>120</option>
                                    </Form.Select>
                                </Col>
                                <Col id="refresh-icon" sm={{ offset: 8 }}>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip>
                                                Refresh Table
                                            </Tooltip>
                                        }
                                    >
                                        <Button id="refresh-icon-btn" onClick={refreshTable}>
                                            <ArrowClockwise style={{ cursor: "pointer" }} />
                                        </Button>
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                            <Row className='pt-10'>
                                <Col>
                                    <Form.Label>Facility/Group</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label>Date Filtering Type</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label>Start Date (Custom Range)</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label>End Date (Custom Range)</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Select name="facility" value={controlOptions.facility} onChange={handleChange}>
                                        <option>--Select--</option>
                                        {facilityNames && (
                                            facilityNames.map((facility, index) => (
                                                <option key={index} value={facility}>{facility}</option>
                                            ))
                                        )}
                                    </Form.Select>
                                </Col>
                                <Col>
                                    <Form.Select name="dateFilter" value={controlOptions.dateFilter} onChange={handleChange}>
                                        <option>--Select--</option>
                                        <option>Last Week</option>
                                        <option>Last Month</option>
                                        <option>Last Quarter</option>
                                        <option>Last Year</option>
                                        <option>Yesterday</option>
                                    </Form.Select>
                                </Col>
                                <Col>
                                    <Form.Control type="date"
                                        name="startDate"
                                        value={controlOptions.startDate}
                                        max={new Date().toISOString().split("T")[0]}
                                        onChange={handleChange}
                                    />
                                </Col>
                                <Col>
                                    <Form.Control type="date"
                                        name="endDate"
                                        value={controlOptions.endDate}
                                        max={new Date().toISOString().split("T")[0]}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </Row>
                            <Row className="pt-20">
                                <Col>
                                    <Form.Label>
                                        Conformance Field of Interest
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label>
                                        Conformance Status
                                    </Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Select name="fieldInterest" value={controlOptions.fieldInterest} onChange={handleChange}>
                                        {fieldInterest && (
                                            fieldInterest.map((field, index) => (
                                                <option key={index} value={field.value}>{field.label}</option>
                                            ))
                                        )}
                                    </Form.Select>
                                </Col>
                                <Col>
                                    <Form.Select name="conformanceStatus" value={controlOptions.conformanceStatus} onChange={handleChange}>
                                        <option value="">--Select--</option>
                                        <option value="2">All</option>
                                        <option value="1">Conformant</option>
                                        <option value="0">Non-Conformant</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <div className="medrec-fallout-table">
                    <MuiThemeProvider theme={theme}>
                        <MaterialTable
                            icons={tableIcons}
                            columns={[
                                {
                                    title: 'file_id',
                                    field: 'file_id',
                                    type: 'string',
                                },
                                {
                                    title: 'encounter_id',
                                    field: 'encounter_id',
                                    type: 'string',
                                },
                                {
                                    title: 'Is Field Conformant?',
                                    field: 'field_of_interest',
                                    type: 'string',
                                    render: rowData => {
                                        if (rowData[controlOptions.fieldInterest] === 1) {
                                            return "Conformant";
                                        }
                                        else {
                                            return "Non-Conformant";
                                        }
                                    },
                                    cellStyle: (e, rowData) => {
                                        if (rowData[controlOptions.fieldInterest] === 1) {
                                            return {
                                                backgroundColor: '#57C4AD',     //conformant 
                                                color: 'white',
                                                textAlign: 'center'
                                            };
                                        }
                                        else {
                                            return {
                                                backgroundColor: '#DB4325',     //not conformant
                                                color: 'white',
                                                textAlign: 'center'
                                            };
                                        }
                                    }
                                },
                            ]}
                            data={tableData}
                            options={{
                                headerStyle: {
                                    backgroundColor: '#073271',
                                    color: 'white',
                                },
                                search: false,
                                pageSize: 5,
                                pageSizeOptions: [5, 10, 30, 60, 90, 120],
                                selection: true,
                                emptyRowsWhenPaging: false
                            }}
                            actions={[
                                {
                                    tooltip: 'Download all the selected MedRec files.',
                                    icon: () => downloadIcon(),
                                    onClick: downloadFiles
                                }
                            ]}
                            title=""
                            localization={{
                                body: {
                                    emptyDataSourceMessage:
                                        (isLoading)
                                            ? 'Loading results...'
                                            : 'There are no results to show.',
                                },
                            }}
                        />
                    </MuiThemeProvider>
                </div>
            </LoadingOverlay>
        </>
    );
}

export default MedRecFalloutDashboard;