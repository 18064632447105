import React, { useEffect, useRef } from 'react';
import allActions from './state/actions';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './routing/Routes'

const App = () => {
  const isMountedRef = useRef(null);
  const globalDispatch = useDispatch();

  function parseJwt(token) {
    if (!token) { return; }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }
  //pull tokens out of window into redux
  useEffect(() => {
    isMountedRef.current = true;
    const setStateTokens = ((idToken, accessToken, refreshToken, falloutPermission, role) => {
      globalDispatch(allActions.tokenActions.setIdToken(idToken));
      globalDispatch(allActions.tokenActions.setRefreshToken(refreshToken));
      globalDispatch(allActions.tokenActions.setAccessToken(accessToken));
      globalDispatch(allActions.tokenActions.setFalloutPermission(falloutPermission));
      globalDispatch(allActions.tokenActions.setRole(role));
    });

    window.addEventListener('message', receiveMessage, false);
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      const accessToken = process.env.REACT_APP_COGNITO_ACCESS_TOKEN;
      const idToken = process.env.REACT_APP_COGNITO_ID_TOKEN;
      const refreshToken = process.env.REACT_APP_COGNITO_REFRESH_TOKEN;
      const parsedJwtData = parseJwt(idToken);
      const falloutPermission = parsedJwtData['custom:access_adt_fallout'];
      const role = parsedJwtData['custom:role'];
      setStateTokens(idToken, accessToken, refreshToken, falloutPermission, role)
    }

    function receiveMessage(event) {

      if (
        event.origin !== process.env.REACT_APP_EVENT_ORIGIN &&
        event.origin !== process.env.REACT_APP_EVENT_ORIGIN_UPHIE
      ) {
        return;
      }

      //checks if it's in an iframe
      if (window !== window.parent) {
        if (event.data && (event.origin === process.env.REACT_APP_EVENT_ORIGIN
          || event.origin === process.env.REACT_APP_EVENT_ORIGIN_UPHIE)) {
          const eventData = JSON.parse(event.data);
          let externalAccessToken = eventData['access_token'];
          let externalIdToken = eventData['id_token'];
          let externalRefreshToken = eventData['refresh_token'];
          const parsedJwtData = parseJwt(externalIdToken);
          const externalFalloutPermission = parsedJwtData['custom:access_adt_fallout'];
          const role = parsedJwtData['custom:role'];
          setStateTokens(externalIdToken, externalAccessToken, externalRefreshToken, externalFalloutPermission, role)
        }
      }
    }

    return () => (isMountedRef.current = false);
  }, [globalDispatch]);

  return (
    <Router>
      <Routes />
    </Router>
  );
}

export default App;
