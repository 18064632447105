const setAccessToken = (token) => {
  return {
    type: 'SET_ACCESS_TOKEN',
    payload: token,
  };
};

const setIdToken = (token) => {
  return {
    type: 'SET_ID_TOKEN',
    payload: token,
  };
};

const setRefreshToken = (token) => {
  return {
    type: 'SET_REFRESH_TOKEN',
    payload: token,
  };
};

const setFormCode = (formCode) => {
  return {
    type: 'SET_FORM_CODE',
    payload: formCode,
  };
};

const setFalloutPermission = (falloutPermission) => {
  return {
    type: 'SET_FALLOUT_PERMISSION',
    payload: falloutPermission,
  };
};

const setRole = (role) => {
  return {
    type: 'SET_ROLE',
    payload: role,
  };
};

const TokenActions = {
  setAccessToken,
  setIdToken,
  setRefreshToken,
  setFormCode,
  setFalloutPermission,
  setRole
};

export default TokenActions;
