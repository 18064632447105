import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';

const DownloadHelpIcon = () => {

    return (
        <OverlayTrigger
            placement="bottom"
            overlay={
                <Tooltip>
                    To download messages: select the checkbox next to the messages you would like to download, then click
                    the download icon that will appear above the upper right corner of the table. If you would like to download
                    <i> all</i> returned messages, select the check box in the table header found next to the "Message Date"
                    column and click the download icon that will appear above the upper right corner of the table.
                </Tooltip>
            }
        >
            <InfoCircle style={{ cursor: "pointer", marginLeft: '10px' }} />
        </OverlayTrigger>
    );
};

export default DownloadHelpIcon;