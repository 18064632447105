import React from 'react';

const Conformance = () => {
  return (
    <>
      <h1><b>Welcome to Conformance Reporting</b></h1>
      <h5 className="pt-3" style={{ color: "black" }}>Please select the tab in the left corner to view your report options.</h5>
    </>
  );
};

export default Conformance;
